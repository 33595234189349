import React from "react";

const Projects = () => {
  return (
    <div id="our-projects" className="padding-bottom">
      <div className="container">
        <div className="section-title">
          <h1>Our Current Projects</h1>
        </div>
        <div
          id="cause-list-carousel"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#cause-list-carousel"
              data-slide-to="0"
              className="active"
            />
            <li data-target="#cause-list-carousel" data-slide-to="1" />
          </ol>
          <div className="carousel-inner">
            <div className="item active">
              <div className="row">
                <div className="col-sm-4">
                  <div className="list-image border-one">
                    <img
                      className="img-responsive"
                      src="images/projects/project5.png"
                      alt=""
                    />
                  </div>
                  <div className="list-info">
                    <h2>Primary Education - Periya Neelavanai</h2>
                    <p>
                      BUDS is financially supporting and managing a project of
                      providing after school classes for students of year 1-6.
                      At present 140 underprivileged students are benefitting
                      from this project. BUDS is paying for the teachers and
                      also a staff to coordinate and manage the project since
                      November 2018. <br />
                      <br />
                      Subjects:Tamil, maths and science.
                      <br />
                      English Classes for Grade 3-6
                    </p>
                    <a href="#donate" className="btn btn-primary">
                      Donate
                    </a>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="list-image border-two">
                    <img
                      className="img-responsive"
                      src="images/projects/project4.png"
                      alt=""
                    />
                  </div>
                  <div className="list-info">
                    <h2>Hindu College - Batticaloa</h2>
                    <p>
                      This project provides financial assistance for 13
                      underprivileged A/L Students with food and accomodation
                      since April 2019 ( Maximum of 20 students can be
                      accommodated). <br />
                      <br /> A full time experinced Warden is recruited to be on
                      site day and night. The running cost is this project works
                      out at £50 per month per child. <br />
                      <br />
                      <br />
                      <br />
                    </p>
                    <a href="#donate" className="btn btn-primary">
                      Donate
                    </a>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="list-image border-three">
                    <img
                      className="img-responsive"
                      src="images/projects/project1.png"
                      alt=""
                    />
                  </div>
                  <div className="list-info">
                    <h2>Kaluvanjikudy</h2>
                    <p>
                      BUDS is covering the costs of private lectures and
                      workshop fees for 3 underprivilaged post graduates to
                      prepare for SLAS, SLEAS, LLV, RDO and MA exams.
                    </p>
                    <a href="#donate" className="btn btn-primary">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="row">
                <div className="col-sm-4">
                  <div className="list-image border-one">
                    <img
                      className="img-responsive"
                      src="images/projects/project3.png"
                      alt=""
                    />
                  </div>
                  <div className="list-info">
                    <h2>
                      AEDU (Association for the Educational Development of the
                      Underprivileged)
                    </h2>
                    <p>
                      BUDS started a novel project of caring for the orphaned
                      children at the homes of their own relatives. It is titled
                      BUDS Foster Care Project and is developed as a better
                      alternative to caring for these children at the
                      orphanages. The children would be free of the stigma
                      “orphans” and would grow up with their uncles, aunties and
                      cousins and would enjoy the warmth of their family.
                      Sponsors make standing order contributions into separate
                      BUDS Foster Care Account and the transfer of money,
                      management of the project and monitoring is carried out by
                      BUDS branch in Batticaloa and a local NGO called AEDU
                      (Association for the Educational Development of the
                      Underprivileged).
                    </p>
                    <a href="#donate" className="btn btn-primary">
                      Donate
                    </a>
                  </div>
                </div>
                {/* <div className="col-sm-4">
                  <div className="list-image border-two">
                    <img
                      className="img-responsive"
                      src="images/projects/project2.png"
                      alt=""
                    />
                  </div>
                  <div className="list-info">
                    <h2>Project5</h2>
                    <p>
                      On the other hand, we denounce with righteous indignation
                      and dislike.
                    </p>
                    <a href="#donate" className="btn btn-primary">
                      Donate
                    </a>
                  </div>
                </div> */}
                {/* <div className="col-sm-4">
                  <div className="list-image border-three">
                    <img
                      className="img-responsive"
                      src="images/projects/project1.png"
                      alt=""
                    />
                  </div>
                  <div className="list-info">
                    <h2>Project6</h2>
                    <p>
                      On the other hand, we denounce with righteous indignation
                      and dislike.
                    </p>
                    <a href="#donate" className="btn btn-primary">
                      Donate
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
