import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <a href="index.html">
              <img className="img-responsive" src="images/logo.png" alt="" />
            </a>
          </div>
          <div className="col-sm-4">
            <div className="copyright-text">
              <p>
                &copy; copyright {new Date().getFullYear()} by BUDS. All rights
                reserved.
              </p>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="footer-socials">
              <ul>
                <li>
                  <a
                    href="/docs/buds-privacy-policy-gdpr.pdf"
                    className="copyright-text"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/BUDS-475837672556803"
                    target="_blank"
                    className="copyright-text"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
