import React from 'react';
const Events = () => {
  return (
    <div id="events">
      <br />
      <div className="container">
        <div className="section-title">
          <h1>Events</h1>
        </div>
        <div className="our-details-tab padding-bottom">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <img
                className="img-responsive"
                src="images/events/calendar_events_22_23.png"
                alt="Calendar Events 2022 - 2023"
              />
            </div>
            {/* <div className="col-sm-12 col-md-6">
              <p>
                The World has never faced a crisis like COVID-19. The Pandemic is impacting communities everywhere and our small home town Batticaloa in the Eastern Province is not an exception. The coronavirus outbreak is the greatest global health emergency in living memory. It is affecting the way we all go about our daily lives.
                In these uncertain times, small acts of kindness can make a huge difference.
              </p>
              <p>
               BUDS-UK and FOBH joined hands to support our community in the Eastern Province to tackle the ongoing, ever-changing situation concerning the coronavirus (COVID-19) pandemic, and are organising a fund raising Christmas Event 2021 to be held on 11th December 2021.
               But it’s the generosity of your support that enables our work to happen quickly and efficiently. We rely on your support to keep us going. With your help, we’ll continue to provide vital humanitarian, individual support for the most vulnerable, and those who fall through the gaps.
               Please join us with your friends and families and enjoy an evening with lots of fun and at the same time help our community in Batticaloa.
               There is someone who needs you today. Your donation can change their life.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
