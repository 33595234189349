import React from 'react';

const HomeWhoAreWe = () => {
  return (
    <React.Fragment>
      <div id="who-we-are">
        <br />
        <div className="container">
          <div className="our-details-tab padding-bottom">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div
                  id="about-carousel"
                  className="carousel slide carousel-fade"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="item active">
                      <img
                        className="img-responsive"
                        src="images/about/who-are-we-1.png"
                        alt=""
                      />
                    </div>
                    <div className="item">
                      <img
                        className="img-responsive"
                        src="images/about/who-are-we-2.png"
                        alt=""
                      />
                    </div>
                    <div className="item">
                      <img
                        className="img-responsive"
                        src="images/about/who-are-we-3.png"
                        alt=""
                      />
                    </div>
                    <div className="item">
                      <img
                        className="img-responsive"
                        src="images/about/who-are-we-4.png"
                        alt=""
                      />
                    </div>
                    <div className="item">
                      <img
                        className="img-responsive"
                        src="images/about/who-are-we-5.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 tab-section">
                <ul className="nav nav-tabs nav-justified" role="tablist">
                  <li className="active">
                    <a href="#story" role="tab" data-toggle="tab">
                      Our Story
                    </a>
                  </li>
                  <li>
                    <a href="#mission" role="tab" data-toggle="tab">
                      Mission
                    </a>
                  </li>
                  <li>
                    <a href="#activity" role="tab" data-toggle="tab">
                      Our Activity
                    </a>
                  </li>
                  <li>
                    <a href="#beneficiaries " role="tab" data-toggle="tab">
                      Beneficiaries
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade in active" id="story">
                    <h4>
                      Batticaloa Underprivileged Development Society (BUDS).
                    </h4>
                    <p>
                      BUDS is a non-governmental, non-religious and
                      non-profitable organisation committed to actively enhance
                      the dignity and quality of life of individuals, families
                      and communities by eliminating barriers to opportunity and
                      helping people in need reach their fullest potential
                      through the power of work and education.
                    </p>
                    <p>
                      Our vision of the future is to build on our current high
                      standards of "care for life" programmes in respecting the
                      individuals, families and communities we serve of their
                      independent lifestyle and we will constantly strive to
                      bring about change and continuous improvement.
                    </p>
                  </div>
                  <div className="tab-pane fade who-are-we" id="mission">
                    <p>
                      Our main object is to uplift the needy in Batticaloa and
                      neighbouring areas in health, shelter, education and to
                      live happily with respect and equal to others. In addition
                      we care for the health of the elderly Sri Lankan
                      expatriates living in the UK.
                    </p>
                    <p>
                      The diversified objectives of BUDS enabled the charity to
                      be involved in multi-dimensional activities across the
                      East of Srilanka such as:
                    </p>
                    <ul>
                      <li>Providing financial assistance to orphanages</li>
                      <li>
                        Providing educational support to underprivileged
                        children
                      </li>
                      <li>Providing medical equipment to hospitals</li>
                      <li>
                        Profiding financial assistance to local non-governmental
                        organisation (NGOs) to conduct Health Clinics and
                        special medical screening programmes in remote rural
                        areas.
                      </li>
                    </ul>
                  </div>
                  <div className="tab-pane fade " id="activity">
                    <p>
                      BUDS involved itself in helping not only the deserving
                      people at home in Srilanka but also the expatriates in
                      this country, to access and utilize the various medical
                      facilities available in this country
                    </p>{' '}
                  </div>
                  <div className="tab-pane fade who-are-we" id="beneficiaries">
                    <p>
                      Our main beneficiaries are the underprivileged children.
                      BUDS currently and previously supported the following
                      orphanages (Children homes) and organisations.
                    </p>
                    <ul>
                      <b>Current</b>
                      <li>Ozanam- Sathurukondan, Batticaloa</li>
                      <li>YMCA- Batticaloa</li>
                      <li>
                        AEDU (Association for Educational Development of the
                        Underprivileged) - Batticaloa
                      </li>
                    </ul>
                    <ul>
                      <b>Previous</b>
                      <li>Ramakrishna Mission - Kallady Uppodai, Batticaloa</li>
                      <li>Dharisanam - Kallady Uppodai, Batticaloa</li>
                      <li>Mangaiyarkarasiyar Mahalir Illam - Batticaloa</li>
                      <li>Assisi Boys Home - Kurukkalmadam, Batticaloa</li>
                      <li>
                        St.Helen Children's Home / ST. THERESA'S CONVENT
                        -Sinnauppodai, Batticaloa
                      </li>
                      <li>
                        Swami Vipulananda Children Home- Akkaraipattu, Amparai
                      </li>
                      <li>
                        Vipulananda Children's Home- Thiruppalugamam, Batticaloa
                      </li>
                      <li>
                        Thirugnanasampanthar Kurukulam - Kaluthavalai,
                        Batticaloa
                      </li>
                      <li>
                        Harri Children's Home - Kallady Uppodai Batticaloa
                      </li>
                      <li>EDS (Education Development Society) - Batticaloa</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <a
              href="/docs/secretary-annual-report-agm-2020.pdf"
              className="d-block btn btn-default contact-info"
              target="_blank"
            >
              Secretary’s Annual Report AGM 2020
            </a>
            &nbsp;&nbsp;
            <a
              href="/docs/agm-postponement-letter.pdf"
              className="d-block btn btn-default contact-info"
              target="_blank"
            >
              AGM postponement notice
            </a>
            &nbsp;&nbsp;
            <a
              href="/docs/BUDSUK-newsletter-2020-final.pdf"
              className="d-block btn btn-default contact-info"
              target="_blank"
            >
              Newsletter 2020
            </a>
             <a
              href="https://www.yumpu.com/en/document/read/65900954/buds-uk-newsletter-issue-2-october-2021"
              className="d-block btn btn-default contact-info"
              target="_blank"
            >
              Newsletter 2021
            </a>&nbsp;&nbsp;
            <a
              href="/docs/buds-election-procedures.docx"
              className="d-block btn btn-default contact-info"
              target="_blank"
            >
              BUDS Election Procedures
            </a>
            &nbsp;&nbsp;
            <a
              href="/docs/buds-constitution-with-2020-amendments.docx"
              className="d-block btn btn-default contact-info"
              target="_blank"
            >
              BUDS Constitution with 2020 amendments
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeWhoAreWe;
