import React from 'react';
import Slider from './slider';
import HomeWhoAreWe from './who-are-we';
import Projects from './projects';
import Events from './events'
import HowToHelp from './how-to-help';
import OurMember from './our-member';
import Contact from './contact';
import 'font-awesome/css/font-awesome.css';
import PhotoGallery from './photo-gallery';
import Words from './words';
// import Events from './events';
import Covid from './covid-19';

const Home = () => {
  return (
    <React.Fragment>
      <Slider />
      <Projects />
      <HomeWhoAreWe />
      <Events />
      <HowToHelp />
      <Contact />
      <OurMember />
      <Words />
      <PhotoGallery />
      <Covid />
    </React.Fragment>
  );
};

export default Home;
