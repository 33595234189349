import React from 'react';

const PhotoGallery = () => {
    return (
        <div id="photo-gallery" className="padding-bottom-two">
		<br/><br/>
		<div className="container-fluid">
			<div className="row">
				<div className="col-sm-3 col-md-2">
					<div className="single-photo">
						<div className="gallery-content">
							<img className="img-responsive" src="images/gallery/thuraineelavani-1.jpg" alt="" />
							<div className="photo-info">							
								<a href="images/gallery/full/thuraineelavani-1.jpg" data-gallery="prettyPhoto"><i className="fa fa-camera"></i></a>
								<h4>Education For Child</h4>
								<span>BUDS</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-2">
					<div className="single-photo">
						<div className="gallery-content">
							<img className="img-responsive" src="images/gallery/thuraineelavani-2.jpg" alt="" />
							<div className="photo-info">							
								<a href="images/gallery/full/thuraineelavani-2.jpg" data-gallery="prettyPhoto"><i className="fa fa-camera"></i></a>
								<h4>Education For Child</h4>
								<span>BUDS</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-2">
					<div className="single-photo">
						<div className="gallery-content">
							<img className="img-responsive" src="images/gallery/thuraineelavani-5.jpg" alt="" />
							<div className="photo-info">							
								<a href="images/gallery/full/thuraineelavani-5.jpg" data-gallery="prettyPhoto"><i className="fa fa-camera"></i></a>
								<h4>Education For Child</h4>
								<span>BUDS</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-2">
					<div className="single-photo">
						<div className="gallery-content">
							<img className="img-responsive" src="images/gallery/thuraineelavani-6.jpg" alt="" />
							<div className="photo-info">							
								<a href="images/gallery/full/thuraineelavani-6.jpg" data-gallery="prettyPhoto"><i className="fa fa-camera"></i></a>
								<h4>Education For Child</h4>
								<span>BUDS</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-2">
					<div className="single-photo">
						<div className="gallery-content">
							<img className="img-responsive" src="images/gallery/thuraineelavani-7.jpg" alt="" />
							<div className="photo-info">							
								<a href="images/gallery/full/thuraineelavani-7.jpg" data-gallery="prettyPhoto"><i className="fa fa-camera"></i></a>
								<h4>Education For Child</h4>
								<span>BUDS</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-2">
					<div className="single-photo">
						<div className="gallery-content">
							<img className="img-responsive" src="images/gallery/thuraineelavani-13.jpg" alt="" />
							<div className="photo-info">							
								<a href="images/gallery/full/thuraineelavani-13.jpg" data-gallery="prettyPhoto"><i className="fa fa-camera"></i></a>
								<h4>Education For Child</h4>
								<span>Wasim Ahmed</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-2">
					<div className="single-photo">
						<div className="gallery-content">
							<img className="img-responsive" src="images/gallery/help1.png" alt="" />
							<div className="photo-info">							
								<a href="images/gallery/full/help1.png" data-gallery="prettyPhoto"><i className="fa fa-camera"></i></a>
								<h4>Education For Child</h4>
								<span>Wasim Ahmed</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-2">
					<div className="single-photo">
						<div className="gallery-content">
							<img className="img-responsive" src="images/gallery/help2.png" alt="" />
							<div className="photo-info">							
								<a href="images/gallery/full/help2.png" data-gallery="prettyPhoto"><i className="fa fa-camera"></i></a>
								<h4>Education For Child</h4>
								<span>Wasim Ahmed</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-2">
					<div className="single-photo">
						<div className="gallery-content">
							<img className="img-responsive" src="images/gallery/help3.png" alt="" />
							<div className="photo-info">							
								<a href="images/gallery/full/help3.png" data-gallery="prettyPhoto"><i className="fa fa-camera"></i></a>
								<h4>Education For Child</h4>
								<span>BUDS</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-2">
					<div className="single-photo">
						<div className="gallery-content">
							<img className="img-responsive" src="images/gallery/help4.png" alt="" />
							<div className="photo-info">							
								<a href="images/gallery/full/help4.png" data-gallery="prettyPhoto"><i className="fa fa-camera"></i></a>
								<h4>Education For Child</h4>
								<span>BUDS</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-2">
					<div className="single-photo">
						<div className="gallery-content">
							<img className="img-responsive" src="images/gallery/help5.png" alt="" />
							<div className="photo-info">							
								<a href="images/gallery/full/help5.png" data-gallery="prettyPhoto"><i className="fa fa-camera"></i></a>
								<h4>Education For Child</h4>
								<span>BUDS</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-2">
					<div className="single-photo">
						<div className="gallery-content">
							<img className="img-responsive" src="images/gallery/help6.png" alt="" />
							<div className="photo-info">							
								<a href="images/gallery/full/help6.png" data-gallery="prettyPhoto"><i className="fa fa-camera"></i></a>
								<h4>Education For Child</h4>
								<span>BUDS</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    );
}

export default PhotoGallery;