import React from "react";

const Home = () => {
  return (
    <div>
      <div className="preloader">
        <div id="loaderImage" />
      </div>

      <div id="carousel-wrapper">
        <div id="main-carousel" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li
              data-target="#main-carousel"
              data-slide-to="0"
              className="active"
            />
            <li data-target="#main-carousel" data-slide-to="1" />
            <li data-target="#main-carousel" data-slide-to="2" />
            <li data-target="#main-carousel" data-slide-to="3" />
            <li data-target="#main-carousel" data-slide-to="4" />
          </ol>
          <div className="carousel-inner">
            <div
              className="item active"
              style={{
                backgroundImage: "url('images/home/orphanage.png')",
              }}
            >
              <div className="carousel-caption container">
                <h1 className="animated rollIn">
                  Supprting Foster Care programme for orphanage children living
                  with their relatives
                </h1>
                <a
                  href="#who-we-are"
                  id="tohash"
                  className="animated rollIn btn btn-primary"
                >
                  Get Start
                </a>
              </div>
            </div>
            <div
              className="item"
              style={{
                backgroundImage: "url(images/home/thiraymadu1.jpg)",
              }}
            >
              <div className="carousel-caption container">
                <h1 className="animated rollIn">
                  Providing educational support to underprivileged children
                </h1>
                <a
                  href="#who-we-are"
                  id="tohash1"
                  className="animated rollIn btn btn-primary"
                >
                  Get Start
                </a>
              </div>
            </div>
            <div
              className="item"
              style={{
                backgroundImage: "url(images/home/srilankan-people.jpg)",
              }}
            >
              <div className="carousel-caption container">
                <h1 className="animated rollIn">
                  Profiding financial assistance to local non-governmental
                  organisation (NGOs) to conduct Health Clinics and special
                  medical screening programmes in remote rural areas
                </h1>
                <a
                  href="#who-we-are"
                  id="tohash2"
                  className="animated rollIn btn btn-primary"
                >
                  Get Start
                </a>
              </div>
            </div>
            <div
              className="item"
              style={{ backgroundImage: "url(images/home/orphanage2.png)" }}
            >
              <div className="carousel-caption container">
                <h1 className="animated rollIn">
                  Providing financial assistance to orphanages
                </h1>
                <a
                  href="#who-we-are"
                  id="tohash3"
                  className="animated rollIn btn btn-primary"
                >
                  Get Start
                </a>
              </div>
            </div>
            <div
              className="item"
              style={{ backgroundImage: "url(images/home/poor-family.png)" }}
            >
              <div className="carousel-caption container">
                <h1 className="animated rollIn">
                  Uplift the needy in Batticaloa and neighbouring areas in
                  health, shelter and education
                </h1>
                <a
                  href="#who-we-are"
                  id="tohash4"
                  className="animated rollIn btn btn-primary"
                >
                  Get Start
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
