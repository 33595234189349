import React from 'react';

const Header = () => {
  return (
    <header id="navigation">
      <div className="navbar main-nav" role="banner">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <a className="navbar-brand" href="/">
              <h1>
                <img
                  className="img-responsive"
                  src="images/logo.png"
                  alt="logo"
                />
              </h1>
            </a>
          </div>
          <div className="top-bar">
            <span className="contact-mail">
              <a className="contact-info" href="mailto:secretary@budsuk.org">
                <i className="fa fa-envelope-o" />
                secretary@budsuk.org
              </a>
            </span>
          </div>
          <nav className="collapse navbar-collapse navbar-right">
            <ul className="nav navbar-nav">
              <li className="active">
                <a href="/">
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a href="#events">
                  <span>Events</span>
                </a>
              </li>
              <li>
                <a href="#covid-19">
                  <span>COVID-19 Relief</span>
                </a>
              </li>
              <li>
                <a href="#who-we-are">
                  <span>Who We Are</span>
                </a>
              </li>
              <li>
                <a href="#our-projects">
                  <span>Our Projects</span>
                </a>
              </li>
              <li>
                <a href="#donate">
                  <span>Donate</span>
                </a>
              </li>
              <li>
                <a href="#our-member">
                  <span>Get Involve</span>
                </a>
              </li>
              <li>
                <a href="#photo-gallery">
                  <span>Gallery</span>
                </a>
              </li>
              <li>
                <a href="#contact">
                  <span>Contact</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
