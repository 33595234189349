import React from "react";

const Words = () => {
  return (
    <div id="word-for-humanity" className="padding-top parallax-section">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 text-center">
            <h1>Some Words For BUDS</h1>
            <div
              id="humanity-carousel"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="item active">
                  <img
                    className="img-responsive img-circle"
                    src="images/partner/partner1.png"
                    alt=""
                  />
                  <p>
                    BUDS provides an annual contribution of about £6,000 to
                    £7,000 to the underprivileged.
                  </p>
                  <p className="word-author">Umachandran</p>
                </div>
                <div className="item">
                  <img
                    className="img-responsive img-circle"
                    src="images/partner/partner2.png"
                    alt=""
                  />
                  <p>
                    BUDS involved itself in helping not only the deserving
                    people at home in Srilanka but also the expatriates in this
                    country, to access and utilize the various medical
                    facilities available in this country.
                  </p>
                  <p className="word-author">Suja Koreira</p>
                </div>
                <div className="item">
                  <img
                    className="img-responsive img-circle"
                    src="images/partner/partner3.png"
                    alt=""
                  />
                  <p>
                    BUDS involves in multi-dimensional activities across the
                    Batticaloa District and its neighbouring regions
                  </p>
                  <p className="word-author">Esther Yoganathan</p>
                </div>
              </div>
              <ol className="carousel-indicators">
                <li
                  data-target="#humanity-carousel"
                  data-slide-to="0"
                  className="active"
                />
                <li data-target="#humanity-carousel" data-slide-to="1" />
                <li data-target="#humanity-carousel" data-slide-to="2" />
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Words;
