import React, { Component } from 'react';
import axios from 'axios';

class Contact extends Component {
  state = {
    data: {
      name: '',
      email: '',
      message: '',
      buttonText: 'Send Message',
      sent: false,
    },
  };

  resetForm = () => {
    this.setState({
      data: {
        name: '',
        email: '',
        message: '',
        buttonText: 'Thanks for your message, We will contact you soon.',
      },
    });

    setTimeout(() => {
      this.setState({ data: { buttonText: 'Send Message' } });
    }, 10000);
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ buttonText: '...sending' });

    try {
      const response = await axios.post(
        //"https://i8pna5vs35.execute-api.us-east-1.amazonaws.com/dev/email/send",
        'https://kmb3c808z5.execute-api.us-east-1.amazonaws.com/prod/email/send',
        this.state.data
      );

      if (response.status === 200) {
        this.setState({ sent: true }, this.resetForm());
      }
    } catch (error) {
      console.log('Message sent failed: ', error);
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  render() {
    const { data } = this.state;
    return (
      <div id="contact">
        <div className="container">
          <div className="section-title">
            <h1>Contact Us</h1>
          </div>
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="row contact-details">
                <div className="col-sm-4">
                  <span>
                    <i className="fa fa-phone" />
                  </span>
                  <p className="contact-info">+(44) 7983 673881 </p>
                </div>
                <div className="col-sm-4">
                  <span>
                    ß
                    <i className="fa fa-envelope" />
                  </span>
                  <a
                    className="contact-info"
                    href="mailto:secretary@budsuk.org"
                  >
                    secretary@budsuk.org
                  </a>
                </div>
              </div>
              <div className="contact-form">
                <form
                  onSubmit={this.handleSubmit}
                  id="contact-form"
                  className="contact-form"
                  name="contact-form"
                >
                  <div className="row">
                    <div className="form-group col-sm-6">
                      <input
                        type="text"
                        name="name"
                        value={data.name}
                        onChange={this.handleChange}
                        className="form-control"
                        required="required"
                        placeholder="Name"
                      />
                    </div>
                    <div className="form-group col-sm-6">
                      <input
                        type="email"
                        name="email"
                        value={data.email}
                        onChange={this.handleChange}
                        className="form-control"
                        required="required"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="form-group col-sm-12">
                      <textarea
                        name="message"
                        id="message"
                        value={data.message}
                        onChange={this.handleChange}
                        required="required"
                        className="form-control"
                        rows="8"
                        placeholder="Message"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary">
                      {data.buttonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
