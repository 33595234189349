import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from './navigation';
import Footer from './footer';
import Home from './home';
import NotFound from './notfound';

const App = () => {
  return (
    <React.Fragment>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </React.Fragment>
  );
};

export default App;
