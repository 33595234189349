import React from 'react';

const HowToHelp = () => {
  return (
    <div id="donate">
      <div id="video-section" className="parallax-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h1>How Can You Help!</h1>
              <p>
                There are many ways you can help BUDS with your money. You can
                donate at our bank account or donate by phone or post, sponsor a
                child, leave a legacy and much more.
                <br />
                <br />
                <b>Why Donate To Buds:</b>
                <br />
                We work with local families, communities and organisations in
                the Eastern Province of Sri Lanka and surroundings to protect ,
                promote and rehabilitate all children. Our global reach,
                expertise, access, innovation, efficiency are the values that
                guide our work and make BUDS unique.
                <br />
                <br />
                Your money really does make a difference to children's lives.
                BUDS is funded entirely by voluntary contributions.
              </p>
              <a href="#contact" className="btn btn-default">
                Conatct Us
              </a>
              {/* <div className="covid-applead">
                <img src="images/events/covid-19-appeal.jpg" />
              </div> */}
            </div>
            <div className="col-sm-6">
              <div className="tab-content">
                <div className="tab-pane fade in active" id="video">
                  <div className="embed-responsive embed-responsive-4by3">
                    {/* <iframe
                      title="Video"
                      autoplay="false"
                      preload="none"
                      className="embed-responsive-item"
                      src="/videos/how-to-help-1.mp4"
                    /> */}
                    <video
                      width="320"
                      height="240"
                      controls={true}
                      className="embed-responsive-item"
                    >
                      <source
                        src="/videos/how-to-help-1.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowToHelp;
