import React from 'react';
const Covid = () => {
  return (
    <div id="covid-19">
      <br />
      <br />
      <div id="video-section" className="parallax-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="covid-applead">
                <img src="images/events/covid-19-appeal-small.jpg" />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="tab-content">
                <div className="tab-pane fade in active" id="video">
                  <div className="embed-responsive embed-responsive-4by3">
                    <a href="images/events/covid-19-flyer.jpg" target="_blank">
                      <img src="images/events/covid-19-flyer-small.jpg" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Covid;
