import React from "react";

const OurMember = () => {
  return (
    <div id="our-member" className="padding-top-two padding-bottom-two">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="section-title">
              <h1>Our Member</h1>
            </div>
            <div>
              <img
                className="img-responsive"
                src="images/member/members.png"
                alt=""
              />
            </div>
            <br />
            <br />
            <div
              id="member-carousel"
              className="carousel slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                <li
                  data-target="#member-carousel"
                  data-slide-to="0"
                  className="active"
                />
                <li data-target="#member-carousel" data-slide-to="1" />
              </ol>
              <div className="carousel-inner">
                <div className="item active">
                  <div className="row">
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/uma.png"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mr S Umachandran</p>
                            <p>President</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/ju.png"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mrs Muriet Mariampillai</p>
                            <p>Secretary</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/balamurali.png"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mr S BalaMurali</p>
                            <p>Vice President</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/member4.jpg"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mr V.K Paheerathan</p>
                            <p>Treasurer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/member4.jpg"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mr Alan Kangatharan</p>
                            <p>Assistant Secretary</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/member4.jpg"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mr Suman Sangaravel</p>
                            <p>Assistant Treasurer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/roshan.jpg"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mr Roshan Rajaratnam</p>
                            <p>Committee Member</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/ira.png"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mrs Ira Lakshman</p>
                            <p>Committee Member</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="row">
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/suja.png"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mrs Suja Koreira</p>
                            <p>Committee Member</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/shyanthan.png"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mr S Vannithamby</p>
                            <p>Committee Member</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/sundar.png"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mr T Sunthararajah</p>
                            <p>Committee Member</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/member4.jpg"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mr I Indrakumar</p>
                            <p>Committee Member</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <div className="single-member">
                        <div className="member-image">
                          <img
                            className="img-responsive"
                            src="images/member/member4.jpg"
                            alt="Member"
                          />
                          <div className="member-info">
                            <p className="lead">Mr Jeyabalan</p>
                            <p>Committee Member</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="become-volunteer">
              <img
                className="img-responsive"
                src="images/logo-icon.png"
                alt="Member"
              />
              <h1>
                <span>Become a</span>Member
              </h1>
              <p>
                If you would like to become a member of BUDS, please contact us
                or complete the membership form below and send it to us.
              </p>
              <div>
                <a
                  href="/docs/membership-form-2020-final.pdf"
                  target="_blank"
                  className="btn btn-default"
                >
                  Membership
                </a>
                &nbsp;&nbsp;&nbsp;
                <a
                  href="/docs/gift-aid-declaration-2020.pdf"
                  target="_blank"
                  className="btn btn-default"
                >
                  Gift Aid
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMember;
